<template>
  <validation-observer
      ref="allowanceSaveFormRef"
      #default="{invalid}"
  >
    <b-form>
      <b-row class="mt-1">
        <b-col
            cols="4"
            class="text-center"
        >
          <div class="div-img border">
            <b-avatar
                size="200"
                button
                :src="ulrAvatar"
                variant="light-primary"
                square
                class="badge-minimal image"
                badge-variant="success"
                @click="handleClick"
            />
            <div class="middle">
              <b-button
                  class="float-right mb-2"
                  variant="danger"
                  @click="handleClick"
              >
                <feather-icon icon="ImageIcon" /> Upload ảnh
              </b-button>
            </div>
          </div>
          <br>
          <input
              ref="inputFile"
              class="input-file"
              type="file"
              @change="uploadFile"
          >
        </b-col>
        <b-col cols="8">
          <b-row>
            <b-col cols="8">
              <b-form-group label-for="lastName">
                <template v-slot:label>
                  Họ đệm <span class="text-danger">*</span>
                </template>
                <validation-provider
                    #default="{ errors }"
                    name="Họ đệm"
                    rules="required"
                >
                  <b-form-input
                      id="lastName"
                      v-model="dataSendForm.lastName"
                      name="lastName"
                      placeholder="Nhập họ đệm"
                      :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tên <span class="text-danger">*</span>
                </template>
                <validation-provider
                    #default="{ errors }"
                    name="Tên"
                    rules="required"
                >
                  <b-form-input
                      id="name"
                      v-model="dataSendForm.name"
                      type="text"
                      placeholder="Nhập tên"
                      :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="CCCD/CMT"
                  label-for="cccd"
              >
                <b-form-input
                    id="cccd"
                    v-model="dataSendForm.identifyCode"
                    type="text"
                    placeholder="Nhập căn cước công dân hoặc CMT"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label-for="title-em"
              >
                <template v-slot:label>
                  SĐT <span class="text-danger">*</span>
                </template>
                <b-form-input
                    id="SDT"
                    v-model="dataSendForm.phone"
                    placeholder="Nhập số điện thoại"
                    aria-describedby="input-3-live-feedback"
                    :state="statePhone"
                />
                <b-form-invalid-feedback
                    id="input-3-live-feedback"
                >Số điện thoại chưa hợp lệ.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="Ngày sinh"
                  label-for="code-class"
              >
                <flat-pickr
                    v-model="dataSendForm.birthday"
                    class="form-control"
                    :config="config"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Ngày tuyển dụng"
                  label-for="date_enter-class"
              >
                <flat-pickr
                    v-model="dataSendForm.dateEnter"
                    class="form-control"
                    :config="config"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label="Tỉnh"
              label-for="province_code"
          >
            <v-select
                v-model="dataSendForm.provinceCode"
                label="label"
                :options="factProvinces"
                :reduce="option => option.value"
                placeholder="Tỉnh"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label="Huyện"
              label-for="district_code"
          >
            <v-select
                v-model="dataSendForm.districtCode"
                :options="factDistricts"
                :reduce="option => option.value"
                placeholder="Huyện"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label-for="email"
          >
            <template v-slot:label>
              Email <span class="text-danger">*</span>
            </template>
            <b-form-input
                id="email"
                v-model="dataSendForm.email"
                type="email"
                placeholder="Email"
                aria-describedby="input-2-live-feedback"
                :state="stateEmail"
            />
            <b-form-invalid-feedback
                id="input-2-live-feedback"
            >Email chưa hơp lệ.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label="Nơi sinh"
              label-for="birth_place"
          >
            <b-form-input
                id="birth_place"
                v-model="dataSendForm.birthPlace"
                type="text"
                placeholder="Nhập nơi sinh"
                aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label="Địa chỉ liên hệ"
              label-for="permanent_residence"
          >
            <b-form-input
                id="permanent_residence"
                v-model="dataSendForm.permanentResidence"
                placeholder="Nhập hộ khẩu"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Mã CCVC"
              label-for="code-1"
          >
            <b-form-input
                id="code-1"
                v-model="dataSendForm.hrmCode"
                placeholder="Mã CCVC"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label="Dân tộc"
              label-for="ethnic_code"
          >
            <v-select
                v-model="dataSendForm.ethnicCode"
                :options="factEthnics"
                :reduce="option => option.value"
                placeholder="Dân tộc"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label="Tôn giáo"
              label-for="religion_code"
          >
            <v-select
                v-model="dataSendForm.religionCode"
                :options="factReligions"
                :reduce="option => option.value"
                placeholder="Tôn giáo"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
              label="Khoa/bộ môn"
              label-for="department-em"
          >
            <template v-slot:label>
              Khoa/bộ môn <span class="text-danger">*</span>
            </template>
            <validation-provider
                #default="{ errors }"
                name="Khoa/bộ môn"
                rules="required"
            >
              <v-select
                  id="title"
                  v-model="dataSendForm.departmentId"
                  :options="optDepartments"
                  :reduce="option => option.value"
                  label="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Chức danh"
              label-for="title-em"
          >
            <v-select
                v-model="dataSendForm.titleId"
                :options="optTitles"
                :reduce="option => option.value"
                placeholder="Chức danh"
                label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Học vị"
              label-for="degree-em"
          >
            <v-select
                v-model="dataSendForm.academicDegreeId"
                :options="optDegrees"
                :reduce="option => option.value"
                placeholder="Học vị"
                label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Học hàm"
              label-for="rank-em"
          >
            <v-select
                v-model="dataSendForm.academicRankId"
                :options="optRanks"
                :reduce="option => option.value"
                placeholder="Học hàm"
                label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
              label="Địa chỉ (nhà riêng)"
              label-for="address"
          >
            <b-form-input
                id="address"
                v-model="dataSendForm.address"
                placeholder="Nhập thông tin địa chỉ"
            />
          </b-form-group>
        </b-col>
        <b-col
            v-if="isSystemAdmin"
            cols="3"
        >
          <b-form-group label-for="status">
            <template v-slot:label>
              Trạng thái <span class="text-danger">*</span>
            </template>
            <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
            >
              <v-select
                  id="status"
                  v-model="dataSendForm.status"
                  :reduce="option => option.value"
                  :options="optionsStatus"
                  label="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
            v-if="isCreated && accountCreatable"
            cols="6"
        >
          <b-form-group>
            <validation-provider
                name="linking"
                vid="createAccount"
            >
              <b-form-checkbox
                  v-model="dataSendForm.createAccount"
                  :value="1"
                  :unchecked-value="0"
              >
                Tạo tài khoản
              </b-form-checkbox>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
            v-if="isCreated && teacherCreatable"
            cols="6"
        >
          <b-form-group>
            <b-form-checkbox
                v-model="dataSendForm.createTeacher"
                :value="1"
                :unchecked-value="0"
                @change="handleCheckboxChangeTeacher"
            >
              Là giảng viên
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <template v-if="isCreated && dataSendForm.createAccount === 1 && accountCreatable">
          <b-col
              sm="12"
              md="6"
              lg="6"
              xl="6"
          >
            <b-form-group label-for="username">
              <template v-slot:label>
                Tài khoản <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Tài khoản"
                  rules="required-if:createAccount"
              >
                <b-form-input
                    id="username"
                    v-model="dataSendForm.username"
                    name="username"
                    placeholder="Nhập tài khoản"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
              sm="12"
              md="6"
              lg="6"
              xl="6"
          >
            <b-form-group label-for="password">
              <template v-slot:label>
                Mật khẩu <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Mật khẩu"
                  rules="required-if:createAccount|min:8|password"
              >
                <b-form-input
                    id="password"
                    v-model="dataSendForm.password"
                    type="password"
                    name="password"
                    placeholder="Nhập mật khẩu"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="role">
              <template v-slot:label>
                Vai trò <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Vai trò"
                  rules="required-if:createAccount"
              >
                <v-select
                    v-model="dataSendForm.roleIds"
                    multiple
                    :options="roleOptions"
                    :reduce="option => option.id"
                    label="name"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </template>

        <!-- submit and reset -->
        <b-col cols="12">
          <div class="float-left">
            <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="!checkValidForm"
                @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { STATUSES } from '@/const/status'
import {
  BButton, BCol, BRow, BForm,
  BFormGroup, BFormInput, BFormSelect,
  BAvatar, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, min, password, requiredIf,
} from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { hasPermissionForResource, isSystemAdmin, removeVietnamese } from '@/utils'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { Flag } from '@/const/flag'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'FormEmployee',
  components: {
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    BAvatar,
    flatPickr,
  },

  props: {
    dataSendForm: {
      type: Object,
      default: () => ({
        id: null,
        code: '',
        hrmCode: '',
        lastName: '',
        name: '',
        identifyCode: '',
        birthday: '',
        email: '',
        phone: '',
        departmentId: '',
        titleId: '',
        academicRankId: '',
        academicDegreeId: '',
        orderNo: 1,
        address: '',
        status: 1,
        pathFileAvatar: '',
        createAccount: 1,
        createTeacher: 1,
        username: '',
        password: '',
        passportCode: '',
        permanentResidence: '',
        birthPlace: '',
        dateEnter: '',
        provinceCode: '',
        districtCode: '',
        ethnicCode: '',
        religionCode: '',
        roleIds: [],
      }),
    },
    modalShow: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      optionsStatus: [],
      optTitles: [],
      optDepartments: [],
      optDegrees: [],
      optRanks: [],
      disableCode: true,
      mainProps: {
        fluidGrow: true,
        height: 200,
      },
      config: {
        enableTime: false,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: true,
      },
      ulrAvatar: '',
      avatar: [],
      required,
      min,
      password,
      requiredIf,
      roleOptions: [],
    }
  },
  computed: {
    ResourceCode() {
      return ResourceCode
    },
    PermissionCode() {
      return PermissionCode
    },
    Flag() {
      return Flag
    },
    ...mapGetters({
      titles: 'employee/titles',
      academicRanks: 'employee/academicRanks',
      academicDegrees: 'employee/academicDegrees',
      departments: 'employee/departments',
      roles: 'role/roles',
      defaultPassword: 'employee/defaultPassword',
      factProvinces: 'dropdown/factProvinces',
      factDistricts: 'dropdown/factDistricts',
      factEthnics: 'dropdown/factEthnics',
      factReligions: 'dropdown/factReligions',
    }),
    isCreated() {
      return !this.dataSendForm.id
    },
    accountCreatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ACCOUNT)
    },
    teacherCreatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TEACHERS)
    },

    stateEmail() {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.dataSendForm.email)
    },

    statePhone() {
      const regexPhoneNumber = /^(84|0)(3[2-9]|5[6|8]|7[0|6-9]|8[1-6|8]|9[0-9])[0-9]{7}$/
      return regexPhoneNumber.test(this.dataSendForm.phone)
    },

    checkValidForm() {
      return (this.dataSendForm.name && this.dataSendForm.lastName && this.statePhone && this.stateEmail && this.dataSendForm.departmentId)
    },

  },
  watch: {
    'dataSendForm.lastName': function (val) {
      const lastName = this.getFirstCharacterUnAccent(val)
      const nameUnAccent = removeVietnamese(this.dataSendForm.name)
      this.dataSendForm.username = `${lastName}${nameUnAccent}`.toLocaleLowerCase()
    },
    'dataSendForm.name': function (val) {
      const lastName = this.getFirstCharacterUnAccent(this.dataSendForm.lastName)
      const nameUnAccent = removeVietnamese(val)
      this.dataSendForm.username = `${lastName}${nameUnAccent}`.toLocaleLowerCase()
    },
    'dataSendForm.provinceCode': function (val) {
      this.dataSendForm.disableCode = null
      this.getFactDistricts({ provinceCode: val })
    },
  },
  async created() {
    // init data FROM API
    if (!this.dataSendForm.pathFileAvatar) {
      this.ulrAvatar = require('@/assets/images/avatars/default_avatar.jpg')
    } else {
      const resLink = await this.getLinkAvatar(this.dataSendForm.pathFileAvatar)
      if (resLink && resLink.isSuccessful) {
        this.ulrAvatar = resLink.data
      }
    }
    this.optionsStatus = STATUSES.map(item => ({ value: item.value, text: item.label }))

    const promises = [
      this.getDepartments({currentPage: 1, itemsPerPage: 1000, status: 1, organizationId: getUser().orgId,}),
      this.getTitles({ studentOnly: 0 }),
      this.getRanks(),
      this.getDegrees(),
      this.getFactProvinces({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
      this.getFactEthnics({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
      this.getFactReligions({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
    ]
    if (this.isCreated && this.accountCreatable) {
      promises.push(this.getRoles({ currentPage: 1, itemsPerPage: 100 }))
      promises.push(this.getDefaultPassword('ACCOUNT_DEFAULT_PASSWORD'))
    }
    await Promise.all(promises)
    if (this.isCreated && this.accountCreatable) {
      this.dataSendForm.password = this.defaultPassword;
      const roleCodesToAdd = ['BASIC', 'GIANG_VIEN'];
      roleCodesToAdd.forEach(code => {
        const role = this.roles.find(element => element.code === code);
        if (role && !this.dataSendForm.roleIds.includes(role.id)) {
          this.dataSendForm.roleIds.push(role.id);
        }
      })
    }
    const studentRole = this.roles.find(element => element.code === 'STUDENT')
    if (studentRole) {
      this.roleOptions = this.roles.filter(role => role.id !== studentRole.id)
    } else {
      this.roleOptions = this.roles
    }
    this.optDepartments = this.departments.map(item => ({ value: item.id, text: item.name }))
    this.optTitles = this.titles.map(item => ({ value: item.id, text: item.name }))
    this.optRanks = this.academicRanks.map(item => ({ value: item.id, text: item.name }))
    this.optDegrees = this.academicDegrees.map(item => ({ value: item.id, text: item.name }))

    if (!this.isCreated) {
      await this.getFactDistricts({ provinceCode: this.dataSendForm.provinceCode })
    }
  },
  methods: {
    hasPermissionForResource,
    isSystemAdmin,
    // handle even
    ...mapActions({
      getTitles: 'employee/getTitles',
      getDepartments: 'employee/getDepartments',
      uploadAvatar: 'employee/uploadAvatar',
      getLinkAvatar: 'employee/getLinkAvatar',
      getRoles: 'role/getRoles',
      getDefaultPassword: 'employee/getDefaultPassword',
      getFactProvinces: 'dropdown/getFactProvinces',
      getFactDistricts: 'dropdown/getFactDistricts',
      getFactEthnics: 'dropdown/getFactEthnics',
      getFactReligions: 'dropdown/getFactReligions',
      getRanks: 'employee/getRanks',
      getDegrees: 'employee/getDegrees',
    }),
    handleCheckboxChangeTeacher() {
      if (this.dataSendForm.createTeacher === 1) {
        const role = this.roles.find(element => element.code === 'GIANG_VIEN');
        if (role && !this.dataSendForm.roleIds.includes(role.id)) {
          this.dataSendForm.roleIds.push(role.id);
        }
      } else {
        const role = this.roles.find(element => element.code === 'GIANG_VIEN');
        if (role) {
          const index = this.dataSendForm.roleIds.indexOf(role.id);
          if (index !== -1) {
            this.dataSendForm.roleIds.splice(index, 1);
          }
        }
      }
    },
    getFirstCharacterUnAccent(value) {
      let result = ''
      const valueSplits = value.split(' ')
      valueSplits.forEach(element => {
        const firstCharacter = Array.from(element)[0]
        if (firstCharacter) {
          const nameUnAccent = removeVietnamese(firstCharacter)
          result += nameUnAccent
        }
      })
      return result
    },

    handleSaveData(e) {
      this.$emit('saveDataForm', this.dataSendForm)
      e.preventDefault()
    },

    getElementState(errors) {
      return errors.length > 0 ? false : null
    },

    async uploadFile(e) {
      const formData = new FormData()
      const {files} = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      files.forEach(file => formData.append('files', file))
      const params = {
        id: this.dataSendForm.id,
        params: formData,
      }
      const response = await this.uploadAvatar(params)
      if (response.isSuccessful) {
        this.showToast('Cập nhật avatar thành công', 'CheckIcon', 'success')
        const resLink = await this.getLinkAvatar(response.data[0])
        if (resLink.isSuccessful) {
          this.ulrAvatar = resLink.data
        }
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      // this.$refs.inputFile.reset()
      this.$refs.inputFile.value = null
    },

    handleClick() {
      this.$refs.inputFile.click()
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

  },
}
</script>

<style type="text/css">

.image {
  opacity: 1;
  transition: .5s ease;
  backface-visibility: hidden;
  cursor: none;
}

.input-file {
  opacity: 0;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.div-img:hover .image {
  opacity: 0.3;
}

.div-img:hover .middle {
  opacity: 1;
}

.text {
  background-color: #e1affd;
  color: white;
  font-size: 16px;
  padding: 10px 30px;
}
</style>
